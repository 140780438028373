import * as React from "react"
import PageLayout from "../../components/pageLayout";
import {GatsbyImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../../components/seo";

const EnergiPage = () => {
    const {
        energi,
        data
    } = useStaticQuery(graphql`
        {
            energi: file(relativePath: { eq: "energi.jpg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            data: markdownRemark(frontmatter: {slug: {eq: "bransjer/energi"}}) {
                id
                frontmatter {
                    subTitle
                    title
                    bruksomrader
                }
                html
            },
        }
    `)

    return (
        <PageLayout>
            <Seo title={"Energi"}/>
            <div className="relative bg-secondary-light">
                <div className="lg:absolute lg:inset-0">
                    <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
                        <GatsbyImage
                            className="h-56 w-full object-cover lg:absolute lg:h-full"
                            image={energi.childImageSharp.gatsbyImageData}
                        />
                    </div>
                </div>
                <div
                    className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
                    <div className="lg:col-start-2 lg:pl-8">
                        <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                            <h2 className="leading-6 text-primary font-semibold tracking-wide uppercase">{data.frontmatter.subTitle}</h2>
                            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">{data.frontmatter.title}</h3>
                            {/*<p className="mt-8 text-lg text-gray-500">Sagittis scelerisque nulla cursus in enim*/}
                            {/*    consectetur quam. Dictum urna sed consectetur neque tristique pellentesque. Blandit*/}
                            {/*    amet, sed aenean erat arcu morbi.</p>*/}
                            <div className="mt-5 text-gray-200">
                                <article className="prose text-gray-200" dangerouslySetInnerHTML={{ __html: data.html }}></article>
                                <div className="bg-primary text-secondary p-4 my-4">
                                    <h3 className="font-bold">BRUKSOMRÅDER:</h3>
                                    <ul className="list-disc p-2 px-5">
                                        {data.frontmatter.bruksomrader.map(item => {
                                            return <li className="px-5 py-1">{item}</li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default EnergiPage
